import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useUser} from '../UserContext';
import UsersService from '../../services/UsersService';
import TestsService from '../../services/TestsService';
import CandidatesService from '../../services/CandidatesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import AlertError from '../Shared/AlertError';
import AlertSuccessCandidate from '../Shared/AlertSuccessCandidate';
import { Switch } from '@headlessui/react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FormatDate} from '../../utils/dates'
import {classNames} from '../../utils/general'
import * as CSV from 'csv-string';

export default function ImportCandidates (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const history = useHistory();

    const [tests, setTests] = useState([]);
    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [csvCandidates, setCsvCandidates] = useState();
    const [candidateTests, setCandidateTests] = useState([]);
    const [notificationActive, setNotificationActive] = useState(true);    
    const [multipleSessions, setMultipleSessions] = useState(false); // default is all tests in one session
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [allowResume, setAllowResume] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [showScheduleTest, setShowScheduleTest] = useState(false)


    const getTomorrow = () => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1);
        return tomorrow;        
    }

    const updateScheduleDate = (state) => {
        if (state == true) {
            setScheduleDate(getTomorrow());
        }
        else {
            setScheduleDate(null);
        }
        setShowScheduleTest(state)        
    }

    useEffect( async () => {
        // get test lists
        const testList = await TestsService.getTests(user.accessToken.token);
        setTests(testList);
        // get notification status
        const me = await UsersService.getMe(user.accessToken.token);
        setNotificationActive(me.NotificationActive == 1? true:false);
        
        setMounted(true);
    }, []);

    const onSubmit = async() => {
        //console.log(data);
        setSubmitting(true);

        const arrCandidates = CSV.parse(csvCandidates);
        //console.log(arrCandidates)


        // validation
        const err = validateForm();
        //console.log(err)
        if (err.length > 0 ) {
            // we have errors
            setErrorList(err);
            setErrors(true);

        }
        else {
            try {
                for (let i=0; i<arrCandidates.length; i++) {
                    if (arrCandidates[i].length==3 && arrCandidates[i][0] != '' && arrCandidates[i][1] != '' && arrCandidates[i][2] != ''  ) {
                        await CandidatesService.addCandidate(user.accessToken.token, arrCandidates[i][0].trim(), arrCandidates[i][1].trim(), arrCandidates[i][2].trim(), candidateTests, FormatDate(scheduleDate), candidateTests.length > 1 ? multipleSessions : false, allowResume);

                    }
                }
                setSuccess(true);
                setErrors(false)

            } catch (e) {
                console.log(e);
                setErrors(true);

            }       

        }
        setSubmitting(false);
        
        window.scrollTo(0, 0)


    }

    const handleSelectTest = (e) => {
        //setCandidateTests(Array.from(e.target.selectedOptions, item => item.value));
        //const selected= Array.from(e.target.selectedOptions, item => item.value);

        //console.log('---')
        let list = [];
        const id= Array.from(e.target.selectedOptions, item => item.value);
        id.forEach( (i) => {
            //console.log({testId: i, testName: tests.find(x=>x.TestId == i).Name})
            list.push({id: i, name: tests.find(x=>x.TestId == i).Name});
        });
        setCandidateTests(list);

        //setCandidateTests(selected);
    }

    const validateForm = () => {
        let err = [];
        if (!csvCandidates || csvCandidates.trim() =='') err.push('CSV is required');
        if (!candidateTests || candidateTests.length==0) err.push('Test is required')

        return err;
    }

  
    return ( 
        <>
        <Header nav='Candidates'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64">
                    <h1 className="font-semibold text-gray-900 text-xl">Import candidates</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                {success ? <AlertSuccessCandidate title='Candidates imported successfully' /> : ''}

                {errors ? <AlertError title='Please correct the following errors' list={errorList}/> : ''}

                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div className="flex  sm:pt-5">
                        <label htmlFor="csv" className="w-1/5 font-medium text-gray-700 ">
                            Candidates list <br />(CSV format)
                        </label>
                        <div className="w-3/5">
                            <textarea id='csv' rows={10} onChange={(e) => setCsvCandidates(e.target.value)} className='p-1 rounded-md w-full' />
                            <p className='text-sm text-gray-400 mt-2'><strong>Format:</strong> First name, Last name, Email (One candidate per line)<br />
                                Example: John, Doe, johndoe@gmail.com</p>

                        </div>
                    </div>                    


                    <div className="flex  sm:pt-5">
                        <label className="w-1/5 font-medium text-gray-700 ">
                            Test(s)
                        </label>
                        <div className="w-3/5">
                            <select multiple={true} size={10} onChange={ (e) => handleSelectTest(e)} className='p-1 rounded-md w-full'>
                            {tests.map((test) => (
                                <option key={test.TestId} value={test.TestId} >{test.Name}</option>
                                ))}                                    
                            </select>  
                        </div>
                    </div>                    

                    {candidateTests.length > 1 ?
                    <div className="flex  sm:pt-5">
                            <label htmlFor="role" className="w-1/5 font-medium text-gray-700  ">
                                Multiple sessions 
                            </label>
                            <div className="w-3/5">
                            <Switch
                                checked={multipleSessions}
                                onChange={() => {setMultipleSessions(!multipleSessions);}}
                                className={classNames(
                                    multipleSessions ? 'bg-green-600' : 'bg-red-600',
                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                                )}
                                >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                    multipleSessions ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                    )}
                                />
                                </Switch>
                            </div>
                        </div>    
                    : ''    }            

                    <div className="flex  sm:pt-5">
                        <label className="w-1/5 font-medium text-gray-700 ">
                            Schedule test
                        </label>
                        <div className="w-1/5 flex">
                            {showScheduleTest ? 
                            <DatePicker className='p-1 rounded-md' selected={scheduleDate} onChange={(date) => {setScheduleDate(date);}} minDate={getTomorrow()} dateFormat="yyyy-MM-dd"/>
                            : 
                            'Now'}

                            <button onClick={()=> updateScheduleDate(!showScheduleTest)} className='ml-4 text-blue-600 hover:text-blue-800'>Change</button>
                        </div>
                    </div>                    

                    <div className="flex  sm:pt-5">
                        <label className="w-1/5 font-medium text-gray-700 ">
                            Allow to resume
                        </label>
                        <div className="w-3/5">
                            {/* <input type="checkbox" onChange={() => setAllowResume(!allowResume)} className='p-1 rounded-md'/> */}

                            <Switch
                                checked={allowResume}
                                onChange={() => {setAllowResume(!allowResume);}}
                                className={classNames(
                                    allowResume ? 'bg-green-600' : 'bg-red-600',
                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                                )}
                                >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        allowResume ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                    )}
                                />
                            </Switch>

                        </div>
                    </div>                    

                    <div className='flex sm:pt-2 sm:mt-px'>
                        <button type="button" onClick={onSubmit} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none cursor-pointer'>
                            Submit
                            {submitting ? 
                            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>                
                            : ''}
                        </button>

                        <button type='button' onClick={() => history.push('/candidates')} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none cursor-pointer'>Cancel</button>

                    </div>

                    </div>
                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}